<template>
  <div
    :style="`height: 600px; background-size: cover; 
      background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(0,0,0,0)), url('${image}');`"
    class="w-100 d-flex align-items-center position-relative"
  >

  <!--  -->
    <div class="container">
      <div style="max-width: 700px">
        <span id="header">
          <h1 class="text-dark display-4 font-weight-bolder">Product Services</h1>
          <p class="text-dark" style="max-width: 300px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisi
            vel consectetur euismod,
          </p>
        </span>
      </div>
      <router-link to="/contacts"
        class="btn mt-1"
        style="background: #ef6d22; border-radius: 30px; color: white !important"
      >
        CONTACT US
      </router-link>
    </div>

    <!-- <img :src="`${image}`" class="position-absolute" style="z-index:-1;height:600px" /> -->
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: "",
    },
    header_eng: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
  computed: {
    currentLocale() {
      return this.$store.state.locales.CURRENT;
    },
    header_content(){
      if(this.currentLocale == 'EN')
      return this.header_eng;
      else 
      return this.header; 
    }
  },
  watch: {
    header_content: function (val) { 
      document.getElementById("header").innerHTML = val; 
    },
  },
  mounted() {},
};
</script>
