<template>
  <div class="py-5" :style="`background: ${ index % 2 != 0 ? '#fff' : '#e8f1f8'}`">
    <div class="container">
      <div v-html="data.pl5_header" class="mb-4"></div>
      <div class="row align-items-center" v-if="index % 2 != 0">
        <div class="col-lg-6">
          <div v-if="data.childrens">
            <template v-for="item in data.childrens">
              <div class="card shadow" :key="item.pl5c_id">
                <div class="card-body" v-html="item.pl5c_content"></div>
              </div>
            </template>
          </div>
        </div>
        <div class="col-lg-6">
          <img :src="data.pl5_image" class="w-100" />
        </div>
      </div>
      <div class="row align-items-center" v-else>
        <div class="col-lg-6">
          <img :src="data.pl5_image" class="w-100" />
        </div>
        <div class="col-lg-6">
          <div v-if="data.childrens">
            <template v-for="item in data.childrens">
              <div class="card shadow" :key="item.pl5c_id">
                <div class="card-body" v-html="item.pl5c_content"></div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
        
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  mounted() {},
};
</script>
